
$--videoplay-thumbnail-width: 122px;
$--videoplay-thumbnail-height: 68px;
$--videoplay-fullscreen-indicator-size: 12px;

#videos {

  .imageNext {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    transition: opacity 200ms;
  }

  .video-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    backdrop-filter: blur(4px);
    cursor: pointer;

    .inner {
      position: absolute;
      width: 150px;
      height: auto;
      top: 50%;
      left: 50%;
      margin: -75px 0 0 -75px;

      svg {
        fill: rgba(255,255,255, 0.85);
        &.vimeo:hover, &.vimeo:focus, &.vimeo:active {
          fill: rgb(0, 173, 239);
        }
        &.youtube:hover, &.youtube:focus, &.youtube:active {
          fill: #d10010;
        }
      }
    }

    &.show {
      display: block;
    }
  }

  .videoConsent {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    backdrop-filter: blur(4px);
    .inner {
      width: 80%;
      height: auto;
      margin: 100px auto;
      color: $white;
      fill: rgba(255,255,255, 0.85);
      h3.title {
        color: $white;
      }
      .action {
        text-align: right;
      }
    }

    &.show {
      display: block;
    }
  }

  .fullscreen-video {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    transition: display 200ms;

    .backdrop {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0, 0.85);
    }

    .top {
      width: 100%;
      height: 82px;
      line-height: 82px;
      text-align: right;
      padding: 15px;
      > svg {
        position: relative;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }
    }
    .center {
      position: relative;
      width: 100vw;
      height: calc(100vh - 82px - 82px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .image {
        margin: 0 auto;
        text-align: center;
        min-width: 50vw;
        aspect-ratio: 16 / 9;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }
      .video-consent {
        position: relative;
        width: 80%;
        height: auto;
        margin: 100px auto;
        color: $white;
        fill: rgba(255,255,255, 0.85);

        max-width: 860px;
        aspect-ratio: 4 / 3;
        background-repeat: no-repeat;
        background-size: cover;

        .filter-blur {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          backdrop-filter: blur(3px);
          background-color: rgba(0,0,0, 0.65);
        }

        .inner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
        }
        h3.title {
          color: $white;
        }

      }
      .video_player {
        display: none;
        width: 80vw;
      }
      .prev,
      .next {
        display: none;
        position: absolute;
        cursor: pointer;
      }
      .prev {
        left: 52px;
      }
      .next {
        right: 52px;
      }
    }
  }

}
