#bigimage {
  position: relative;
  picture {
    display: block;
    width: 100%;
    height: auto;
    mix-blend-mode: multiply;

    > img {
      width: 100%;
    }
  }

  .branding {
    position: fixed;
    z-index: 1;
    font-family: $--font-stack;
    max-width: 20vw;
    height: auto;
    #Pfad_99 {
      backdrop-filter: blur(25px) brightness(35%);
      opacity: 0.75;
    }
  }

  .language-switch {
    position: absolute;
    width: 200px;
    top: 20px;
    left: calc(50% - 100px);
    color: $white;
    text-align: center;
    z-index: 5;
    font-size: 14px;
  }

  &.match .branding {
    position: absolute;
    transform: translateY(100%);
  }

  .tt-logo {
    position: absolute;
    left: 50%;
    top: 65%;
    transform: translate(-50%,-50%);
    max-width: 40vw;
    max-height: 40%;
  }

  .copyright {
    position: relative;
    bottom: 25px;
    font-size: $--font-size-small;
    color: $white;
  }
  @include media-breakpoint-down(xl) {
    .tt-logo {
      top: 20%;
    }
  }

  @include media-breakpoint-down(md) {
    height: 100%;
    width: auto;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    picture {
      height: auto;
      width: auto;
    }
    .branding {
      max-width: 30vw;
    }
    .tt-logo {
      max-width: 60vw;
      width: 60vw;
      top: 55%;
    }
    .language-switch {
      text-align: center;
    }
  }

}
