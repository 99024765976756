body {
  height: auto;
  min-height: 100%;
}

/*html, body {
  overflow-x: hidden;
  height: 100%;
}*/

#app {
  min-height: 60vh;
  max-width: $--app-width-max;
  //overflow-x: hidden;
  position: relative;
  margin: 0 auto;
  background-color: $--color-primary-10;
}

#bigimage {
  max-width: 1920px;
  width: 100%;
  transition: visibility ease-in-out 200ms;
}

#mainnav {
  min-height: 80px;
  width: 100%;
  max-width: $--app-width-max;
}

#breadcrumb {
  padding-top: 80px;
}

#mainnav.has-bigimage {
  //visibility: hidden;
  position: fixed;
  width: 80px;
  right: 0;
  z-index: 2;
  transition: all 0.3s;
  backdrop-filter: none;
  background-color: transparent;

  > .brand,
  > .nav-icons {
    visibility: hidden;
  }

  .sidebarIconToggle .spinner {
    background-color: $white;
  }

  &.fadeIn {
    backdrop-filter: blur(30px) brightness(1.33) opacity(1);
    background-color: rgba(255, 255, 255, .3);
    width: 100%;
    left: auto;
    right: auto;
    > .brand,
    > .nav-icons {
      visibility: visible;
    }

    .sidebarIconToggle .spinner {
      background-color: $black;
    }
    input[type=checkbox]:checked ~ .sidebarIconToggle > .spinner {
      background-color: $white;

    }
  }
  @include media-breakpoint-up(xl) {
    &:not(.fadeIn) {
      right: 0;
      left: 0;
      width: 100vw;
      position: absolute;
    }
  }
}

//#bigimage + #mainnav + #breadcrumb {
#mainnav + #bigimage + #breadcrumb {
  padding-top: 0px;
}

@include media-breakpoint-down(md) {
  #content {
    padding-top: 80px;
    > div {
      padding-top: 30px;
      &.no-top-padding {
        padding-top: 0;
      }
    }
  }
  //#bigimage + #mainnav + #breadcrumb + #content {
  #mainnav + #bigimage + #breadcrumb + #content {
    padding-top: 0px;
  }
}
