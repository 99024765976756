@use "sass:math";

/* --- GENERAL: typo --- */
html {
  font-size: 100% * math.div(strip-units($fab-rem-base), 16);
}
body {
  font-family: $--font-stack;
  color: $--font-color-base;
  font-size: $fab-font-size-body;
  line-height: 1.4;
}

a {
  color: $--font-color-link;
  &:hover,
  &:active {
    color: $--font-color-link
  }
}

h1, h2, h3, h4 {
  color: $--font-color-base;
  font-family: $--font-stack-deko;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .light {
    font-weight: normal;
  }
}

h1 {
  // font-size: $--font-size-base * 2.5;
  // line-height: $--font-size-base * 2.5 * 1.2;
  font-size: $fab-font-size-h1;
  line-height: 1.33;
  text-align: left;
  font-weight: 700;
  margin-bottom: 50px;
  > .subline {
    display: block;
    font-weight: 500;
  }
  &:after {
    background-color: transparent;
    display: none;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 30px;
  }
}
h2 {
  //font-size: 1.3rem;
  font-size: $fab-font-size-h2;
  line-height: 1.33;
  margin-top: 0;
}
h3 {
  font-size: $fab-font-size-h3;
  line-height: 1.33;
  font-weight: 700;
}

.text-small {
  font-size: $--font-size-small;
}
