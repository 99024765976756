footer {
  background-color: $--color-primary-30;
  padding: 67px 0 0 0;


  .footernav {
    font-family: $--font-stack;
    font-size: fab-unit(16, 18);
    line-height: 1.4;
    color: $--color-black-footer;
    h3 {
      font-size: fab-unit(16, 18);
      font-family: $--font-stack-deko;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .sponsorclaim {
    margin: 95px auto 50px auto;
    text-align: center;
    font-size: $--font-size-small;
  }

  .partners {
    margin: 50px 0 50px 0;
    img {
      max-height: 80px;
      margin: 0 30px 30px;
      mix-blend-mode: multiply;
    }
  }

  .copyright {
    background-color: $--color-primary-10;
    font-size: $--font-size-small;
    text-align: center;
    padding: 10px;
  }

}

