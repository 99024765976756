
$--imagecarousel-fullscreen-indicator-size: 12px;

#image-gallery {

  --imagecarousel-indicator-size: 12px;
  --imagecarousel-indicator-active-size: 48px;
  @include media-breakpoint-down(md) {
    --imagecarousel-indicator-size: 7px;
    --imagecarousel-indicator-active-size: 31px;
  }

  .gallery {
    position: relative;
  }


  .gallery-indicator {
    padding-top: 15px;
  }

  .indicator {
    display: inline-block;
    width: var(--imagecarousel-indicator-size);
    height: var(--imagecarousel-indicator-size);
    margin: 0 var(--imagecarousel-indicator-size);
    background-color: $--font-color-base;
    border-radius: calc(var(--imagecarousel-indicator-size) / 2);
    cursor: pointer;
    &.active {
      width: var(--imagecarousel-indicator-active-size);
    }
    &.vimeo:hover, &.vimeo:focus, &.vimeo:active {
      background-color: rgb(0, 173, 239);
    }
    &.youtube:hover, &.youtube:focus, &.youtube:active {
      background-color: #d10010;
    }
  }

  .fullscreen-image {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    transition: display 200ms;

    .backdrop {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0, 0.85);
      @include media-breakpoint-down(md) {
        background-color: rgba(0, 0, 0, 0.95);
      }
    }

    .top {
      width: 100%;
      height: 82px;
      line-height: 82px;
      text-align: right;
      padding: 15px;
      > .close {
        position: relative;
        top: 15px;
        right: 45px;
        cursor: pointer;
      }
    }
    .center {
      position: relative;
      width: 100vw;
      height: calc(100vh - 82px - 82px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .image {
        margin: 0 auto;
        text-align: center;
        /*min-width: 50vw;
        @include media-breakpoint-down(md) {
          min-width: 60vw;
        }*/
      }
      figure {
        position: relative;
        width: 100%;
        max-height: calc(100vh - 82px - 82px);
        max-width: 100vw;
        img {
          max-width: 100%;
          max-height: 100%;
          transition: filter 100ms;
          &.loading {
            filter: blur(5px);
          }
        }
        figcaption {
          position: absolute;
          bottom: 5px;
          left: 20px;
          font-size: 14px;
          color: $white;
          text-align: start;
          .title {
            display: block;
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
      .prev,
      .next {
        position: absolute;
        cursor: pointer;
      }
      .prev {
        left: 52px;
      }
      .next {
        right: 52px;
      }
      @include media-breakpoint-down(md) {
        .prev,
        .next {
          > svg {
            height: 24px;
            width: auto;
          }
        }
        .prev {
          left: 24px;
        }
        .next {
          right: 24px;
        }
      }
    }
    .bottom {
      position: relative;
      width: 100%;
      height: 82px;
      line-height: 82px;
      text-align: center;
      z-index: 3;

      .indicator {
        background-color: $white;
        &.vimeo:hover, &.vimeo:focus, &.vimeo:active {
          background-color: rgb(0, 173, 239);
        }
        &.youtube:hover, &.youtube:focus, &.youtube:active {
          background-color: #d10010;
        }
      }
    }
  }

}
