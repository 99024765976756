#calendar {
  $title-min-height: 48px;
  $time-font-size: 24px;
  .singleday {
    margin-bottom: 100px;
  }
  .date-header {
    border-bottom: 2px solid $black;
    line-height: $fab-line-height-h1;
    .today {
      margin-top: fab-unit(2, 5);
      font-family: $--font-stack-deko;
      font-size: fab-unit(22, 36);
      line-height: fab-unit(28, 50);
      font-weight: bold;
      @include media-breakpoint-up(lg) {
        text-align: center;
      }
    }
    .date {
      font-size: fab-unit(28, 50);
      line-height: fab-unit(28, 50);
      font-family: $--font-stack-deko;
      font-weight: bold;
      color: $--color-primary;
      margin-bottom: 20px;
    }
  }
  .event {
    padding: 30px 0;
    border-bottom: 1px solid $black;
    &:last-child {
      border-bottom: none;
    }
  }
  .time {
    align-items: center;
    .time_start, .time_end {
      font-family: $--font-stack-deko;
      font-size: 24px;
      font-weight: bold;
      @include media-breakpoint-down(md) {
        font-size: 18px;
      }
      @include media-breakpoint-up(lg) {
        display: block;
        text-align: center;
        .time_start {
          //margin-top: calc(($title-min-height - 24px) / 2);
          margin-top: fab-unit(9, 12);
        }
        .time_end {
          margin-top: calc(($title-min-height - 24px) / 2 - 9px);
        }
      }
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
    @include media-breakpoint-up(lg) {
      .time_start {
        //margin-top: calc(($title-min-height - 24px) / 2);
        margin-top: fab-unit(9, 12);
      }
      .time_end {
        margin-top: calc(($title-min-height - 24px) / 2 - 9px);
      }
    }

    .time_divider {
      display: inline-block;
      width: 20px;
      height: 3px;
      margin: 0 3px;
      background-color: $black;
      @include media-breakpoint-up(lg) {
        display: block;
        margin: 5px auto 0;
      }
    }
  }
  .details {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    > div {
      width: 100%;
    }
  }
  .title {
    font-family: $--font-stack-deko;
    font-size: $fab-font-size-h1;
    line-height: $fab-line-height-h1;
    min-height: $title-min-height;
    font-weight: bold;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .subline {
    min-height: $fab-line-height-h1;
    font-size: $fab-font-size-body;
    vertical-align: bottom;
    align-self: flex-end;
    margin: 8px 0 0 0;
    .highlight, .genre, .location {
      font-weight: bold;
      &::after {
        content: "|";
        display: inline-block;
        margin-left: 5px;
      }
    }
    .highlight {
      color: $--color-primary;
    }

    @include media-breakpoint-down(md) {
      margin: 12px 0 0 0;
      .highlight, .genre, .location::after {
        margin-right: 5px;
      }
      .location::after {
        display: none;
      }
    }
  }
}

.dropdown-container {
  position: relative;
  display: flex;
  width: 500px;
  justify-content: end;
  > div {
    position: fixed;
  }

  @include media-breakpoint-down(md) {
    width: 200px;
  }
}

.fab-dropdown {
  --bs-dropdown-link-hover-bg: $white;
  .fab-dropdown-toggle {
    display: inline-block;
    appearance: button;
    height: fab-unit(32, 60);
    padding: 0 fab-unit(16, 30);
    background-color: $white;
    border: 0;
    border-radius:  fab-unit(16, 30);
    font-size: fab-unit(16, 24);
    line-height: fab-unit(32, 60);;
    font-family: $--font-stack-deko;
    font-weight: bold;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    outline: none;
    color: $--font-color-base;

    @include media-breakpoint-down(md) {
      > svg {
        height: 12px;
        margin-top: -2px;
      }
    }

    &:hover, &:focus, &:active {
      text-decoration: none;
    }

    .closed { display: inline-block; }
    .opened { display: none; }

    &.show {
      outline: none;
      background-color: $white;
      border: 0;
      border-radius: fab-unit(16, 30) fab-unit(16, 30) 0 0;

      .closed { display: none; }
      .opened { display: inline-block; }
    }
  }
  .dropdown-menu {
    border: 0;
    border-radius: fab-unit(16, 30);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
    &[data-popper-placement="bottom-end"],
    &.dropdown-menu-end {
      margin-top: -2px !important;
      border-radius:  fab-unit(16, 30) 0 fab-unit(16, 30) fab-unit(16, 30);
      .dropdown-item {
        text-align: right;
      }
    }
    .dropdown-item {
      font-size: fab-unit(16, 24);
      line-height: fab-unit(26, 38);
      &:hover, &:active {
        background-color: transparent;
      }
    }
  }
}
