
.masonry-grid {
  --grid-gutter: 10px;
  -webkit-column-count: 4; /* Chrome, Safari, Opera */
  -moz-column-count: 4; /* Firefox */
  column-count: 4;

  grid-column-gap: 10px;

  column-gap: 10px;grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-rows: masonry;

  .grid-item {
    margin-bottom: var(--grid-gutter);

    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;

    display: block;
    height: auto;
  }
  .grid-item--width2 { width: 400px; }

  @include media-breakpoint-down(xl) {
    -webkit-column-count: 3; /* Chrome, Safari, Opera */
    -moz-column-count: 3; /* Firefox */
    column-count: 3;
  }
  @include media-breakpoint-down(lg) {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
  }
  @include media-breakpoint-down(md) {
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1; /* Firefox */
    column-count: 1;
  }
}


