
#sidebarMenu {
  height: 100%;
  position: absolute;
  visibility: hidden;
  top: 0;
  right: 0;
  width: 437px;
  height: 100vh;
  z-index: 1;
  margin-top: 0px;
  transform: translateX(+250px);
  //transition: transform 250ms ease-in-out;
  //transition: visibility 250ms ease-in-out;
  background-color: $black;
  overflow-y: auto;

  .sidebarLanguageSwitch {
    margin:0;
    padding: 20px 20px 44px 47px;
    a {
      color: $white;
      font-size: $--font-size-small;
    }
  }

  @include media-breakpoint-down(md) {
    width: 100vw;
    .sidebarLanguageSwitch {
      padding: 20px 0 20px 5px;
    }
  }
  @include media-breakpoint-up(md) {
    .nav-icons-2 {
      padding-left: 30px;
    }
  }

}

.sidebarMenuInner {
  --bs-accordion-color: $white;
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: transparent;
  --bs-accordion-border-width: 0;
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: 0;
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-btn-padding-y: 0;
  --bs-accordion-btn-color: $white;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.675' height='9.172' viewBox='0 0 14.675 9.172'%3E%3Cpath id='iconmonstr-arrow-65' d='M0 6.23 1.73 4.5l5.61 5.71L12.945 4.5l1.73 1.73L13.3 7.626 7.34 13.672Z' transform='translate(0 -4.5)' fill='%23fff' opacity='0.5'/%3E%3C/svg%3E");
  --bs-accordion-btn-icon-width: 14.675px;
  --bs-accordion-btn-icon-transform: none;
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.675' height='9.172' viewBox='0 0 14.675 9.172'%3E%3Cpath id='iconmonstr-arrow-65' d='M0 1.73 1.73 0 7.34 5.71 12.945 0l1.73 1.73L7.34 9.172Z' transform='translate(14.675 9.172) rotate(180)' fill='%23fff'/%3E%3C/svg%3E");
  --bs-accordion-btn-focus-border-color: transparent;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-body-padding-y: 0;
  --bs-accordion-active-color: $white;
  --bs-accordion-active-bg: transparent;

  height: auto;
  margin:0;
  padding:90px 0 0 47px;
  ////border-top: 1px solid rgba(255, 255, 255, 0.10);

  @include media-breakpoint-down(md) {
    padding:90px 0 0 20px;
  }

  .accordion-button {
    background-color: transparent;
    list-style: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0;
    cursor: pointer;
    border-bottom: none;
    font-size: $--font-size-menu;
    line-height: $--line-height-menu;
    font-family: $--font-stack;
    justify-content: flex-start;
    &::after {
      margin-left: 10px;
      margin-top: 5px;
    }
  }
  .accordion-item {

    .subnav {
      padding-left: 0;
      margin-bottom: 44px;
    }
    li {
      list-style: none;
      color: $white;
      padding: 0;
      font-size: $--font-size-menu;
      line-height: $--line-height-menu;
      display: block;

      a {
        color: $white;
        cursor: pointer;
        text-decoration: none;
        padding: 0;
      }

      @include media-breakpoint-down(md) {
        line-height: $--line-height-menu-md;
      }
    }
  }

  .static-menu {

    h2 {
      margin-bottom: 0;
      > a {
        display: block;
        width: 100%;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0;
        border-bottom: none;
        font-size: $--font-size-menu;
        line-height: $--line-height-menu;
        font-family: $--font-stack;
        &::after {
          margin-left: 10px;
          margin-top: 5px;
        }
      }
    }

    .subnav {
      padding-left: 0;
      margin-bottom: 44px;
      li {
        list-style: none;
        color: $white;
        padding: 0;
        font-size: $--font-size-menu;
        line-height: $--line-height-menu;
        display: block;

        @include media-breakpoint-down(md) {
          line-height: $--line-height-menu-md;
        }

        a {
          color: $white;
          cursor: pointer;
          text-decoration: none;
          padding: 0;
          &:hover, &focus, &:active {
            text-decoration: underline;
          }
        }
      }
    }

  }

}

/* burger menu toggle */
input[type="checkbox"]:checked ~ #sidebarMenu {
  transform: translateX(0);
  visibility: visible;
}

input[type=checkbox] {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}
.sidebarIconToggle {
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  height: 100%;
  width: 100%;
  height: 22px;
  width: 28px;
  top: 30px;
  right: 30px;
}
.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 4px;
  width: 100%;
  background-color: #333;
  transition: background-color 0.5s ease;
}
.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 5px;
}
.diagonal.part-1 {
  position: relative;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
}
.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 5px;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg) translateX(-1px);
  margin-top: 12px;
  background-color: $white;

  height: 2px;
  width: 100%;
  margin-left: 10%;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg) translateX(-1px);
  margin-top: -12px;
  background-color: $white;

  height: 2px;
  width: 100%;
  margin-left: 10%;
}
