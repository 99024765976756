#mainnav {
  position: fixed;
  //background-color: #ffffff;
  backdrop-filter: blur(30px) brightness(1.33) opacity(1);
  background-color: rgba(255, 255, 255, .3);
  z-index: 1;
  top: 0;

  .brand {
    height: 45.8px;
    margin-left: 22px;
  }

  .nav-icons,
  .nav-icons-2 {
    width: 250px;
    a {
      display: inline-block;
      text-decoration: none;
    }
  }

  .nav-icons-2 {
    top: 23px;
    left: 20px;

    a {
      margin-right: 20px;
    }

  }

}
