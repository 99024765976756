@use "sass:map";

#welcome-calendar {
  margin-bottom: 100px;
  @include media-breakpoint-down(md) {
    margin-bottom: 30px;
  }
}

#welcome {
  h1 > .subline {
    display: block;
    font-weight: 500;
  }
}

$fab-calendar-height: 43px;
$fab-calendar-fontsize: 22px;
#calendar {
  h3 {
    line-height: $fab-line-height-h1;
    line-height: calc($fab-font-size-h1 * 1.33);
    width: 100%;
    border-bottom: 1px solid $--font-color-base;
    text-align: center;
    @include media-breakpoint-down(lg) {
      margin-top: 30px;
    }
  }

  .calendarDates {
    display: flex;
    flex-wrap: wrap;
    font-family: $--font-stack-deko;
    font-size: $fab-calendar-fontsize;

    > .day {
      width: calc(100% / 7);
      height: $fab-calendar-height;
      text-align: center;
      line-height: $fab-calendar-height;

      > span, a {
        display: inline-block;
        width: $fab-calendar-height;
        height: $fab-calendar-height;
      }

      > a {
        text-decoration: none;
        &:hover, &:focus, &:active {
          text-decoration: none;
          border: 2px solid $black;
          border-radius: $fab-calendar-height;
          line-height: 39px;
        }
      }

      &.today > span,
      &.today > a {
        border: 2px solid $black;
        border-radius: $fab-calendar-height;
        line-height: 39px;
      }

      &.weekend {
        font-weight: bold;
        &.today > span,
        &.today > a {
          background-color: $black;
          color: $white;
        }
      }

      &.outside {
        opacity: 0.3;
      }
    }
  }
}

#program {
  --home-program-container-width: 100vw;
  --home-program-entries-visible: 4;
  --home-program-entry-spacing: 30px;
  --home-program-entry-cutoff: 60px;

  @include media-breakpoint-up(xxl) {
    // --home-program-container-width: map.get($grid-breakpoints, 'xxl');
    // --home-program-container-width: map-get($grid-breakpoints, 'xxl');
    --home-program-container-width: 1920px;
  }
  @include media-breakpoint-down(xl) {
    --home-program-entries-visible: 3;
  }
  @include media-breakpoint-down(lg) {
    --home-program-entries-visible: 2;
  }
  @include media-breakpoint-down(md) {
    --home-program-entries-visible: 1;
  }

  --home-program-entry-width: calc( ( var(--home-program-container-width) - var(--home-program-entry-cutoff) ) / var(--home-program-entries-visible) - var(--home-program-entry-spacing));

  margin-bottom: 170px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 100px;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 60px;
  }

  > h1 {
    margin-bottom: 20px;
  }

  .slider {
    position: relative;

    .sliderInner {
      width: 100%;
      width: var(--home-program-container-width);

      // hide scrollbar
      overflow-x: scroll;
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
      }

      .scroller {
        width: calc( (var(--home-program-entry-width) + var(--home-program-entry-spacing)) * var(--home-program-entries) + ( 100% - ( var(--home-program-entry-width) + var(--home-program-entry-spacing) ) * var(--home-program-entries-visible)));
        padding-bottom: var(--home-program-entry-spacing);
      }
    }
    .card {
      position: relative;
      width: var(--home-program-entry-width);
      border: 0;
      margin-right: var(--home-program-entry-spacing);
      background-color: $--color-primary-30;
      box-shadow: $fab-boxshadow;

      &.pointer {
        cursor: pointer;
      }
      .card-img-top {
        aspect-ratio: 16 / 9;
      }
      .card-img-top.no-image {
        width: 100%;
        padding-top: 1.777%;
        aspect-ratio: 16 / 9;
        background-color: $--color-primary;
      }
      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        aspect-ratio: 16 / 9;
        background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.05));
      }
      h3 {
        font-size: $fab-font-size-h3;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        // height: calc($fab-line-height-h3 * 3);
      }
      .card-body {
        padding: 30px;
        @include media-breakpoint-down(md) {
          padding: 20px;
        }
      }
      .card-text {
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .card-footer {
        line-height: 1.2;
        padding: 0 30px 30px 30px;
        background-color: transparent;
        border-top: none;
        font-family: $--font-stack-deko;
        font-size: $fab-font-size-h3;
        font-weight: 700;

        .highlight {
          color: $--color-primary;
          @include fluid-type(16px, 18px);
          margin-bottom: 10px;
        }

        @include media-breakpoint-down(md) {
          padding: 0 20px 20px 20px;
        }
      }
    }
    .genre {
      position: absolute;
      top: 0;
      left: 0;
      color: $white;
      font-family: $--font-stack;
      font-weight: bold;
      font-size: fab-unit(18, 20);
      padding: 20px 0 0 30px;

      @include media-breakpoint-down(md) {
        padding: 15px 0 0 20px;
      }
    }
    .sliderNext {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right:0;
      bottom: var(--home-program-entry-spacing);
      min-width: var(--home-program-entry-spacing);
      width: calc( 100% - ( var(--home-program-entry-width) + var(--home-program-entry-spacing) ) * var(--home-program-entries-visible));
      background-color: rgba(0, 0, 0, 0.3);
      cursor: pointer;
      > svg {
        // opacity: 0.3;
        margin-right: -15px;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        > svg {
          opacity: 1;
        }
      }
    }
  }
}

#news {
  --home-news-container-width: 100vw;
  --home-news-entries-visible: 4;
  --home-news-entry-spacing: 30px;
  --home-news-entry-cutoff: 60px;

  @include media-breakpoint-up(xxl) {
    // --home-news-container-width: map.get($grid-breakpoints, 'xxl');
    // --home-news-container-width: map-get($grid-breakpoints, 'xxl');
    --home-news-container-width: 1920px;
  }
  @include media-breakpoint-down(xl) {
    --home-news-entries-visible: 3;
  }
  @include media-breakpoint-down(lg) {
    --home-news-entries-visible: 2;
  }
  @include media-breakpoint-down(md) {
    --home-news-entries-visible: 1;
  }

  --home-news-entry-width: calc( ( var(--home-news-container-width) - var(--home-news-entry-cutoff) ) / var(--home-news-entries-visible) - var(--home-news-entry-spacing));

  margin-bottom: 170px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 100px;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 60px;
  }

  > h1 {
    margin-bottom: 20px;
  }

  h1 > span {
    font-weight: bold;
  }

  .slider {
    position: relative;

    .sliderInner {
      width: 100%;
      width: var(--home-news-container-width);

      // hide scrollbar
      overflow-x: scroll;
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
      }

      .scroller {
        width: calc( (var(--home-news-entry-width) + var(--home-news-entry-spacing)) * var(--home-news-entries) + ( 100% - ( var(--home-news-entry-width) + var(--home-news-entry-spacing) ) * var(--home-news-entries-visible)));
        padding-bottom: var(--home-news-entry-spacing);
      }
    }

    .card {
      position: relative;
      width: var(--home-news-entry-width);
      border: 0;
      margin-right: var(--home-news-entry-spacing);
      background-color: $--color-primary-30;

      box-shadow: $fab-boxshadow;

      &.pointer {
        cursor: pointer;
      }

      h3 {
        font-size: $fab-font-size-h3;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        // height: calc($fab-line-height-h3 * 3);
      }
      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.05));
      }
      .card-body {
        padding: 30px;
      }
      .card-text {
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
    .sliderNext {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right:0;
      bottom: var(--home-news-entry-spacing);
      min-width: var(--home-news-entry-spacing);
      width: calc( 100% - ( var(--home-news-entry-width) + var(--home-news-entry-spacing) ) * var(--home-news-entries-visible));
      background-color: rgba(0, 0, 0, 0.3);
      > svg {
        //opacity: 0.3;
        margin-right: -15px;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        > svg {
          opacity: 1;
          margin-right: -15px;
        }
      }
    }
  }
}

#videos {
  --home-videos-container-width: 100vw;
  --home-videos-entries-visible: 4;
  --home-videos-entry-spacing: 30px;
  --home-videos-entry-cutoff: 60px;

  @include media-breakpoint-up(xxl) {
    // --home-news-container-width: map.get($grid-breakpoints, 'xxl');
    // --home-news-container-width: map-get($grid-breakpoints, 'xxl');
    --home-videos-container-width: 1920px;
  }
  @include media-breakpoint-down(xl) {
    --home-videos-entries-visible: 3;
  }
  @include media-breakpoint-down(lg) {
    --home-videos-entries-visible: 2;
  }
  @include media-breakpoint-down(md) {
    --home-videos-entries-visible: 1;
  }

  --home-videos-entry-width: calc( ( var(--home-videos-container-width) - var(--home-videos-entry-cutoff) ) / var(--home-videos-entries-visible) - var(--home-videos-entry-spacing));

  margin-bottom: 170px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 100px;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 60px;
  }

  > h1 {
    margin-bottom: 20px;
  }

  .slider {
    position: relative;

    .sliderInner {
      width: 100%;
      width: var(--home-videos-container-width);

      // hide scrollbar
      overflow-x: scroll;
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
      }

      .scroller {
        width: calc( (var(--home-videos-entry-width) + var(--home-videos-entry-spacing)) * var(--home-videos-entries) + ( 100% - ( var(--home-videos-entry-width) + var(--home-videos-entry-spacing) ) * var(--home-videos-entries-visible)));
        padding-bottom: var(--home-videos-entry-spacing);
      }
    }

    .card {
      position: relative;
      width: var(--home-videos-entry-width);
      border: 0;
      margin-right: var(--home-videos-entry-spacing);
      background-color: $--color-primary-30;
      aspect-ratio: 16 / 9;

      box-shadow: $fab-boxshadow;

      &.pointer {
        cursor: pointer;
      }
      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.05));
        > svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-70%, -50%);
          width: 20%;
          opacity: 0.5;
        }
        &:hover, &:focus, &:active {
          svg {
            opacity: 0.8;
          }
        }
      }
      h3 {
        position: absolute;
        top: 0;
        left: 0;
        color: $white;
        font-family: $--font-stack;
        font-weight: bold;
        font-size: fab-unit(18, 20);
        padding: 20px 0 0 30px;
      }
      .card-body {
        padding: 30px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      .card-text {
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
    .sliderNext, .sliderRev {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right:0;
      bottom: var(--home-videos-entry-spacing);
      min-width: var(--home-videos-entry-spacing);
      width: calc( 100% - ( var(--home-videos-entry-width) + var(--home-videos-entry-spacing) ) * var(--home-videos-entries-visible));
      background-color: rgba(0, 0, 0, 0.3);
      > svg {
        //opacity: 0.3;
        margin-right: -15px;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        > svg {
          opacity: 1;
        }
      }
    }
  }
}

