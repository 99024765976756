
$--imagecarousel-thumbnail-width: 100px;
$--imagecarousel-thumbnail-height: 56px;
$--imagecarousel-fullscreen-indicator-size: 12px;

#imagecarousel {

  --imagecarousel-indicator-size: 12px;
  --imagecarousel-indicator-active-size: 48px;
  @include media-breakpoint-down(md) {
    --imagecarousel-indicator-size: 7px;
    --imagecarousel-indicator-active-size: 31px;
  }

  .gallery {
    position: relative;
  }

  .bigimage {
    width: 100%;
    aspect-ratio: 613 / 342;
    object-fit: cover;
  }

  /*.imageNext {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    transition: opacity 200ms;
  }*/

  .video-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    backdrop-filter: blur(4px);
    cursor: pointer;

    .inner {
      position: absolute;
      width: 150px;
      height: auto;
      top: 50%;
      left: 50%;
      margin: -75px 0 0 -75px;

      svg {
        fill: rgba(255,255,255, 0.85);
        &.vimeo:hover, &.vimeo:focus, &.vimeo:active {
          fill: rgb(0, 173, 239);
        }
        &.youtube:hover, &.youtube:focus, &.youtube:active {
          fill: #d10010;
        }
      }
    }

    &.show {
      display: block;
    }
  }

  .videoConsent {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    backdrop-filter: blur(4px);
    .inner {
      width: 80%;
      height: auto;
      margin: 100px auto;
      color: $white;
      fill: rgba(255,255,255, 0.85);
      h3.title {
        color: $white;
      }
      .action {
        text-align: right;
      }
    }

    &.show {
      display: block;
    }
  }

  .thumbnails {
    position: absolute;
    bottom: 20px;
    cursor: pointer;

    .thumbnail {
      position: relative;
      width: $--imagecarousel-thumbnail-width;
      height: $--imagecarousel-thumbnail-height;
      box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.56);
      margin: 0 5px;
      overflow: hidden;
      &.active {
        border: 2px solid $white;
        img {
          position: absolute;
          top: -2px;
          left: -2px;
        }
      }
      &.video {
        .play-icon {
          width: 30px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          > svg {
            fill: $white;
            opacity: 0.8;
            transition: opacity 0.2s ease;
          }
          &:hover, &:focus, &:active {
            > svg {
              opacity: 1;
            }
          }
        }
        &:hover, &:focus, &:active {
          .play-icon > svg {
            opacity: 1;
          }
        }
      }
    }
  }

  .gallery-indicator {
    padding-top: 15px;
  }

  .indicator {
    display: inline-block;
    width: var(--imagecarousel-indicator-size);
    height: var(--imagecarousel-indicator-size);
    margin: 0 var(--imagecarousel-indicator-size);
    background-color: $--font-color-base;
    border-radius: calc(var(--imagecarousel-indicator-size) / 2);
    cursor: pointer;
    &.active {
      width: var(--imagecarousel-indicator-active-size);
    }
    &.vimeo:hover, &.vimeo:focus, &.vimeo:active {
      background-color: rgb(0, 173, 239);
    }
    &.youtube:hover, &.youtube:focus, &.youtube:active {
      background-color: #d10010;
    }
  }

  .fullscreen-image {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    transition: display 200ms;

    .backdrop {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0, 0.85);
      @include media-breakpoint-down(md) {
        background-color: rgba(0, 0, 0, 0.95);
      }
    }

    .top {
      width: 100%;
      height: 82px;
      line-height: 82px;
      text-align: right;
      padding: 15px;
      > svg {
        position: relative;
        top: 15px;
        right: 45px;
        cursor: pointer;
      }
    }
    .center {
      position: relative;
      width: 100vw;
      height: calc(100vh - 82px - 82px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .image {
        margin: 0 auto;
        text-align: center;
        min-width: 50vw;
        @include media-breakpoint-down(md) {
          min-width: 60vw;
        }
      }
      figure {
        position: relative;
        width: 100%;
        max-height: calc(100vh - 82px - 82px);
        max-width: 100vw;
        img {
          max-width: 100%;
          max-height: 100%;
          transition: filter 100ms;
          &.loading {
            filter: blur(5px);
          }
        }
        figcaption {
          position: absolute;
          bottom: 5px;
          left: 20px;
          font-size: 14px;
          color: $white;
        }
      }
      .video-consent {
        position: relative;
        width: 80%;
        height: auto;
        margin: 100px auto;
        color: $white;
        fill: rgba(255,255,255, 0.85);

        max-width: 860px;
        aspect-ratio: 4 / 3;
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-down(md) {
          background-image: none !important;
          width: 100%;
        }

        .filter-blur {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          backdrop-filter: blur(3px);
          background-color: rgba(0,0,0, 0.65);
        }

        .inner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
        h3.title {
          color: $white;
        }
        /*.action {
          text-align: right;
        }*/
      }
      .video_player {
        display: none;
        width: 80vw;
      }
      .prev,
      .next {
        position: absolute;
        cursor: pointer;
      }
      .prev {
        left: 52px;
      }
      .next {
        right: 52px;
      }
      @include media-breakpoint-down(md) {
        .prev,
        .next {
          > svg {
            height: 24px;
            width: auto;
          }
        }
        .prev {
          left: 24px;
        }
        .next {
          right: 24px;
        }
      }
    }
    .bottom {
      position: relative;
      width: 100%;
      height: 82px;
      line-height: 82px;
      text-align: center;
      z-index: 3;

      .indicator {
        background-color: $white;
        &.vimeo:hover, &.vimeo:focus, &.vimeo:active {
          background-color: rgb(0, 173, 239);
        }
        &.youtube:hover, &.youtube:focus, &.youtube:active {
          background-color: #d10010;
        }
      }
    }
  }

}
