
#search-form {
  margin-bottom: 50px;

  input {
    position: relative;
    min-height: $fab-line-height-h1;
    font-size: $fab-font-size-h1;
    font-weight: bold;
    font-family: $--font-stack-deko;
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 0px;

    &::placeholder {
      position: absolute;
      color: rgba(0,0,0, 0.35);
      left: 0px;
    }

    //&hover, &:focus, &:active {
    //  background-color: rgba(0,0,0, 0.1);
    //}
  }
}

#no-search-results {
  h2 {
    line-height: fab-unit(28, 50);
    @include fluid-type(28px, 50px);
    font-family: $--font-stack-deko;
    font-weight: bold;
    color: $--color-black-30;
    padding-bottom: 20px;
  }
}

#search-results {

  h2 {
    line-height: fab-unit(28, 50);
    @include fluid-type(28px, 50px);
    font-family: $--font-stack-deko;
    font-weight: bold;
    color: $--color-primary;
    padding-bottom: 20px;
    border-bottom: 2px solid #000;
  }

  ul.result-list {
    padding: 0 0 50px 0;
    list-style: none;
    li {
      padding: 30px 0;
      border-bottom: 1px solid $--color-black-35;
      &:last-child {
        border-bottom: none;
      }

      .title {
        font-family: $--font-stack-deko;
        @include fluid-type(22px, 28px);
        font-weight: bold;
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .subline {
        @include fluid-type(20px, 22px);
        vertical-align: bottom;
        align-self: flex-end;
        .genre, .location {
          font-weight: bold;
        }
      }
    }
  }
}

#no-search-results {
  .fun {
    text-align: center;
    padding-top: 100px;
    > div {
      margin: 25px 0;
    }
    .info,
    h3 {
      @include fluid-type(36px, 50px);
      line-height: 1.2;
    }
  }
}
