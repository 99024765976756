@use "sass:math";
/* layout settings */
$--app-width-max: 1920px;
// $--app-width-max: 100vw;


/* general branding variables */

// colors
$--color-primary: #666 !default;
$--color-primary-30: #aaa !default;
$--color-primary-10: #ccc !default;

$--color-black: #000000;
$--color-black-35: #a6a6a6;
$--color-black-85: #262323;
$--color-black-30: #B0A4A5;
$--color-black-footer: #303030;
$--color-lightgray: rgba(48, 48, 48, 0.35);

@if $color-variant == 'red' {
  $--color-primary: #ff6666;
  $--color-primary-30: #FFD6D6;
  $--color-primary-10: #FFEDED;
} @else if $color-variant == 'cyan' {
  $--color-primary: #7BE5EA;
  $--color-primary-30: #C8F6F8;
  $--color-primary-10: #F2FCFD;
} @else {
  $--color-primary: #666;
  $--color-primary-30: #aaa;
  $--color-primary-10: #ccc;
}


// typo
$--font-stack:    		ff-meta-web-pro, Helvetica, sans-serif;
$--font-stack-num:    ff-meta-web-pro, Helvetica, sans-serif;
$--font-stack-deko: 	itc-avant-garde-gothic-pro, Helvetica, sans-serif;
//$--font-stack-cond: 	ff-meta-web-pro-condensed, Helvetica, sans-serif;

$--font-color-base:   #303030;
$--font-color-link:   #303030;

$--font-size-base: 24px;
$--font-size-small: 14px;
$--font-size-menu: 30px;
$--font-size-medium: 20px;
$--font-size-large: 30px;

$--line-height-menu: 44px;
$--line-height-menu-md: 38px;

/* overwrite default values */
$fab-rem-base: $--font-size-base;
$fab-screen-min: 576;
$fab-screen-opt-start: 992;
$fab-screen-opt-end: strip-units($--app-width-max);
$fab-screen-max: strip-units($--app-width-max);

/* fab fluid typo */
$fab-fluid-min-bp: 576px;
$fab-fluid-max-bp: $--app-width-max;

/* Style guide variables fab-unit */
$fab-font-size-body: fab-unit(18, strip-units($--font-size-base));
$fab-line-height-body: fab-unit(18 * 1.4, strip-units($--font-size-base) * 1.4);

$fab-font-size-body-small: fab-unit(14, 16);
$fab-line-height-body: fab-unit(14 * 1.4, 16 * 1.4);

$fab-font-size-h1: fab-unit(28, 40);
$fab-line-height-h1: fab-unit(32, 48);

$fab-font-size-h2: $fab-font-size-h1;
$fab-line-height-h2: $fab-line-height-h1;

$fab-font-size-h3: fab-unit(22, 24);
$fab-line-height-h3: fab-unit(24, 32);

$fab-space-s: fab-unit(20, 30);
$fab-space-m: fab-unit(40, 80);
$fab-space-l: fab-unit(60, 120);
$fab-space-xl: fab-unit(80, 180);

/* Shadow */
$fab-boxshadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
$fab-boxshadow-1: 0 0 22px 0 rgba(0, 0, 0, 0.22);

/* layout dimensions */
$fab_sidebar-width: 398px;

/* enable css grid */
$enable-cssgrid: false;
