#share {
  padding: 139px 0;
  text-align: center;
  white-space-collapse: discard;
  a {
    text-decoration: none;
    padding: 0 15px;
  }
  .label {
    margin-top: 30px;
    font-size: $--font-size-small;
  }
}
