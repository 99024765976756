@use 'sass:math';

$fab-fluid-min-bp: 320px !default;
$fab-fluid-max-bp: 960px !default;

@function round($number, $decimals: 0) {
  $n: 1;
  @if $decimals > 0 {
    @for $i from 1 through $decimals {
      $n: $n * 10;
    }
  }
  @return math.div(math.round($number * $n), $n);
}

@function px-to-rem($px) {
  $rems: math.div($px, 16px) * 1rem;
  @return $rems;
}

@function fab-fluid($min-size, $max-size, $min-breakpoint: $fab-fluid-min-bp, $max-breakpoint: $fab-fluid-max-bp, $unit: vw) {
  $slope: math.div($max-size - $min-size, $max-breakpoint - $min-breakpoint);
  $slope-to-unit: round($slope * 100, 2);
  $intercept-rem: round(px-to-rem($min-size - $slope * $min-breakpoint), 2);
  $min-size-rem: round(px-to-rem($min-size), 2);
  $max-size-rem: round(px-to-rem($max-size), 2);
  @return clamp(#{$min-size-rem}, #{$slope-to-unit}#{$unit} + #{$intercept-rem}, #{$max-size-rem});
}

/*
 Usage: @include fluid-type(14px, 20px);
 */
@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: $fab-fluid-min-bp, $upper-range: $fab-fluid-max-bp) {

  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));

  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
  }
}

/*Returns a CSS clamp function which resolves to $size-at-min-width when page width is
below $min-width,
$size-at-max-width when page width is above $max-width,
and linearly scales between the two between $min-width and $max-width*/

@function fluid-type($size-at-min-width, $size-at-max-width, $min-width: $fab-fluid-min-bp, $max-width: $fab-fluid-max-bp) {
  $min-width: strip-units($min-width);
  $max-width: strip-units($max-width);
  $slope: ($size-at-max-width - $size-at-min-width) / ($max-width - $min-width);
  $y-axis-intersection: -1 * $min-width * $slope + $size-at-min-width;
  $return-value: clamp(#{$size-at-min-width}, #{$y-axis-intersection} + #{$slope} * 100vw, #{$size-at-max-width});
  @return $return-value;
}
