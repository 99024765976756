#tag-events {
  $title-min-height: 48px;
  $time-font-size: 24px;

  .event {
    padding: 30px 0;
    border-top: 1px solid $black;
    &:last-child {
      border-bottom: 1px solid $black;
    }
  }
  .details {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    > div {
      width: 100%;
    }
  }
  .title {
    font-family: $--font-stack-deko;
    font-size: $fab-font-size-h1;
    line-height: $fab-line-height-h1;
    min-height: $title-min-height;
    font-weight: bold;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .subline {
    min-height: $fab-line-height-h1;
    font-size: $fab-font-size-body-small;
    vertical-align: bottom;
    align-self: flex-end;
    line-height: 48px;
    .genre, .location {
      font-weight: bold;
    }
  }
}


.tag-list {
  .btn {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
