.btn-light {
  transition: transform .5s ease;
  border: 0;
  box-shadow: $fab-boxshadow;

  &:hover, &:focus, &:active {
    text-decoration: none;
  }
  &:hover, &:focus {
    transform: scale(1.05);
    background-color: $white;
    box-shadow: $fab-boxshadow-1;
  }
  &:active {
    background-color: $white;
    box-shadow: none;
  }

  @include media-breakpoint-down(md) {
    font-size: 14px;
  }

}
:checked + .btn-light, :not(.btn-check) + .btn-light:active, .btn-light:first-child:active, .btn-light.active, .btn-light.show {
  background-color: $white;
  box-shadow: none;
}
