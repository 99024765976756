/* global settings */
$color-variant: 'red';

/* functional includes */
@import "mixins/fab-unit";
@import "mixins/fab-slider";
@import "mixins/fab-fluid";

/* basic framework and variables */
@import "variables";
@import "bootstrap";
@import "fonts";

/* libraries */
@import "../../node_modules/plyr/src/sass/plyr.scss";

/* base layout */
@import "typo";
@import "buttons";
@import "layout";
@import "mainnav";
@import "bigimage";
@import "sidebarmenu";
@import "breadcrumb";
@import "imagecarousel";
@import "masonry";
@import "share";
@import "footer";

/* components */
@import "indicator";
@import "videoplay";
@import "sharebox";

/* views */
@import "views/home";
@import "views/event";
@import "views/events";
@import "views/calendar";
@import "views/tag";
@import "views/search";
@import "views/gallery";
