#breadcrumb {
  --bs-breadcrumb-divider: ">";
  --bs-breadcrumb-item-active-color: $--color-black;

  margin: 30px auto 50px;
  font-size: 14px;

  .breadcrumb-item {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .home {
    font-weight: bold;
  }
  .active {
    color: $--color-black;
  }
  a {
    text-decoration: none;
  }
}
