#event-list {

  --bs-accordion-color: $black;
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: transparent;
  --bs-accordion-border-width: 0;
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: 0;
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-btn-padding-y: 0;
  --bs-accordion-btn-color: $black;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg id='arrow_down_black' data-name='arrow down black' xmlns='http://www.w3.org/2000/svg' width='24' height='15' viewBox='0 0 24 15'%3E%3Cpath id='iconmonstr-arrow-65' d='M0 7.33 2.829 4.5 12 13.839 21.171 4.5 24 7.33 12 19.5Z' transform='translate(0 -4.5)' fill='%23303030'/%3E%3C/svg%3E");
  --bs-accordion-btn-icon-width: 24px;
  --bs-accordion-btn-icon-transform: none;
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='15' viewBox='0 0 24 15'%3E%3Cpath id='iconmonstr-arrow-65' d='M0 7.33 2.829 4.5 12 13.839 21.171 4.5 24 7.33 12 19.5Z' transform='translate(24 19.5) rotate(180)'/%3E%3C/svg%3E");
  --bs-accordion-btn-focus-border-color: transparent;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-body-padding-y: 0;
  --bs-accordion-active-color: $black;
  --bs-accordion-active-bg: transparent;

  .accordion-button::after {
    margin-right: 15px;
  }

  .accordion-item {
  }
  .accordion-header {
    .accordion-button,
    .no-accordion-button {
      //font-size: $fab-font-size-h1;
      @include fluid-type(22px, 28px);
      font-family: $--font-stack-deko;
      line-height: 1;

      padding: 30px 0;
      font-size: $fab-font-size-h1;
      font-weight: 700;

      &.collapsed {
        border-bottom: 2px solid $black;
      }
    }
  }
  .accordion-collapse {
    padding-bottom: 150px;
  }

  .grid-item .card {
    border: 0;
    background-color: $--color-primary-30;
    box-shadow: $fab-boxshadow;

    h3 {
      //font-size: $fab-font-size-h3;
      @include fluid-type(20px, 22px);
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      // height: calc($fab-line-height-h3 * 3);
    }
    .card-body {
      padding: 30px;
      @include media-breakpoint-down(md) {
        padding: 20px;
      }
    }
    .card-text {
      @include fluid-type(18px, 22px);
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .card-footer {
      line-height: 1.2;
      padding: 0 30px 30px 30px;
      background-color: transparent;
      border-top: none;
      font-family: $--font-stack-deko;
      @include fluid-type(18px, 22px);
      font-weight: 700;

      @include media-breakpoint-down(md) {
        padding: 0 20px 20px 20px;
      }

      .highlight {
        color: $--color-primary;
        @include fluid-type(16px, 18px);
      }
    }
  }

}
