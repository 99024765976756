/* indicators */
.indicator-container {

  display: none !important;

  --indicator-size: 12px;
  @include media-breakpoint-down(md) {
    --indicator-size: 7px;
  }

  width: 100%;
  .indicator {
    display: inline-block;
    width: var(--indicator-size);
    height: var(--indicator-size);
    margin: 0 var(--indicator-size);
    background-color: $--font-color-base;
    border-radius: calc(var(--indicator-size) / 2);
    cursor: pointer;
    &.active {
      width: 48px;
      margin: 0;
    }
    &.vimeo:hover, &.vimeo:focus, &.vimeo:active {
      background-color: rgb(0, 173, 239);
    }
    &.youtube:hover, &.youtube:focus, &.youtube:active {
      background-color: #d10010;
    }

    @include media-breakpoint-down(xl) {
      &:nth-of-type(2n),
      &:nth-of-type(3n) {
        display: none;
      }
    }
    @include media-breakpoint-down(lg) {
      &:nth-of-type(2n) {
        display: none;
      }
    }
    @include media-breakpoint-down(md) {
      --home-videos-entries-visible: 1;
    }
  }

  &.oversize {
    .indicator {
      display: none;
      &.active, &.first, &.last,
      &.prev, &.prev-1, &.next, &.next-1 {
        display: inline-block;
      }
      &.prev {
        margin-left: calc(var(--indicator-size) * 4);
      }
      &.next {
        margin-right: calc(var(--indicator-size) * 4);
      }
    }
  }
}
