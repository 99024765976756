#sharebox {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 5;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  transition: display 200ms;

  .backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.85);
  }

  .top {
    width: 100%;
    height: 82px;
    line-height: 82px;
    text-align: right;
    padding: 15px;
    > svg {
      position: relative;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }
  .center {
    position: relative;
    width: 100vw;
    text-align: center;
  }
  .inner {
    color: $white;
    a {
      color: $white;
    }
    .title {
      font-family: $--font-stack-deko;
      font-size: $--font-size-large;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }
}
