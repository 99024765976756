
#images-eventdates {
  margin-bottom: 63px;
}

#images {
  margin-bottom: 63px;
  @include media-breakpoint-down(md) {
    margin-bottom: 50px;
  }
}

#eventdates {
  background-color: $--color-primary-30;
  padding: 20px 20px 10px;
  margin-bottom: 63px;
  @include media-breakpoint-up(xl) {
    max-width: $fab_sidebar-width;
  }

  .header {
    > * {
      flex: 1
    }
    h3 {
      flex: 2;
      text-align: center;
      //font-size: fab-fluid(20px, 22px);
      @include fluid-type(22px, 30px);
    }
    .scrollButton {
      text-align: right;
      padding-bottom: 15px;
      a {
        display: none;
        width: 33px;
        height: 33px;
        padding: 0;
        overflow: hidden;

        svg {
          transform: translateY(12px);
        }
      }
    }

  }

  .datelist {
    position: relative;

    .datelistInner {
      max-height: 420px;

      // hide scrollbar
      overflow-y: scroll;
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
      }

      .scroller {
        animation-duration: 10s;
        animation-iteration-count: 2;
        transform-origin: bottom;
        &.bounce {
          //padding-bottom: 385px;
          animation-name: bounce;
          animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
          .item:last-child {
            margin-bottom: 250px;
          }
        }
      }
      @keyframes bounce {
        0%   { transform: translateY(0); }
        10%  { transform: translateY(-50px); }
        13%  { transform: translateY(0); }
        17%  { transform: translateY(-10px); }
        19%  { transform: translateY(0); }
        20%  { transform: translateY(-2px); }
        20.5%  { transform: translateY(0); }
        100% { transform: translateY(0); }
      }

    }

    //font-size: fab-unit(22, 24);
    // @include fluid-type(22px, 23px);
    font-size: 22px;
  }

  .datetime {
    margin-top: 5px;
    border-top: 2px solid $black;
    padding: 3px 0 3px 10px;
    font-family: $--font-stack-deko;
    font-weight: bold;
  }
  .locationhighlight {
    border-top: 1px solid $black;
    font-size: 18px;
    padding: 3px 0 3px 10px;

    @include media-breakpoint-up(xl) {
      > div {
        max-width: $fab_sidebar-width - 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .highlight {
      color: $--color-primary;
      text-align: right;
    }
  }

  @include media-breakpoint-down(lg) {
    .actions {
      margin-top: 20px;
    }
  }
}


#eventDescription {
  //font-site: fab-unit(28, 30);
  @include fluid-type(28px, 30px);
}

#eventText {
  max-width: 1088px;
  margin-bottom: 63px;
  @include media-breakpoint-down(md) {
    margin-bottom: 50px;
  }

  @include media-breakpoint-down(md) {
    h1 {
      margin-bottom: 30px;
    }
  }

  .event-subline {
    margin-bottom: 63px;
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    .highlight {
      color: $--color-primary;
    }
  }

  .action-link {
    margin: 60px 0 100px;
    @include media-breakpoint-down(md) {
      margin-bottom: 50px;

      svg {
        height: 14px;
        width: auto;
      }
    }
  }
}

#eventRelated {
  @include media-breakpoint-up(xl) {
    max-width: $fab_sidebar-width;
  }
  li {
    font-family: $--font-stack-deko;
  }
}


#eventDetails {
  --bs-accordion-color: $black;
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: transparent;
  --bs-accordion-border-width: 0;
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: 0;
  --bs-accordion-btn-padding-x: 0;
  --bs-accordion-btn-padding-y: 0;
  --bs-accordion-btn-color: $black;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg id='arrow_down_black' data-name='arrow down black' xmlns='http://www.w3.org/2000/svg' width='24' height='15' viewBox='0 0 24 15'%3E%3Cpath id='iconmonstr-arrow-65' d='M0 7.33 2.829 4.5 12 13.839 21.171 4.5 24 7.33 12 19.5Z' transform='translate(0 -4.5)' fill='%23303030'/%3E%3C/svg%3E");
  --bs-accordion-btn-icon-width: 24px;
  --bs-accordion-btn-icon-transform: none;
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='15' viewBox='0 0 24 15'%3E%3Cpath id='iconmonstr-arrow-65' d='M0 7.33 2.829 4.5 12 13.839 21.171 4.5 24 7.33 12 19.5Z' transform='translate(24 19.5) rotate(180)'/%3E%3C/svg%3E");
  --bs-accordion-btn-focus-border-color: transparent;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 0;
  --bs-accordion-body-padding-y: 0;
  --bs-accordion-active-color: $black;
  --bs-accordion-active-bg: transparent;


  @include media-breakpoint-down(md) {
    --bs-accordion-btn-icon-width: 12px;
  }

  margin-top: 63px;

  .accordion-header {
    font-size: fab-unit(26, 30);
    line-height: 2.2;
    .accordion-button {
      font-weight: 700;
      &::after {
        margin-right: 10px;
      }
    }
  }
  .accordion-item {
    border-bottom: 2px solid $black;
  }

}

.similar, .tags {
  padding: 0 0 20px 0;
  margin-bottom: 50px;
  @include fluid-type(18px, 20px);

  @include media-breakpoint-down(md) {
    margin-bottom: 10px;
  }
  h3 {
    @include fluid-type(20px, 22px);
    border-bottom: 2px solid $black;
    margin-bottom: 20px;
    padding-left: 10px;
  }
  h5 {
    margin-bottom: 20px;
    padding-left: 10px;
    //font-size: $fab-font-size-h3;
    //@include fluid-type(20px, 23px);
    @include fluid-type(20px, 22px);
    line-height: 2;
    font-family: $--font-stack-deko;
    font-weight: bold;
    border-bottom: 2px solid $black;
   }

  ul {
    padding: 0 0 0 10px;
    li {
      list-style: none;
      padding: 0;
      a {
        text-decoration: none;
      }
    }
  }
}
.similar li a {
  &:hover, &:focus, &:active {
    text-decoration: underline;
  }
}
.tags {
  li {
    margin-bottom: 20px;
    @include media-breakpoint-down(md) {
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 10px;
    }
    a {
      @include fluid-type(18px, 20px);
      //text-transform: lowercase;
    }
  }
}

